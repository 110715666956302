import {
  Box,
  Typography,
  Card,
  Stack,
  SxProps,
  Theme,
  svgIconClasses,
  TypographyProps,
  Skeleton
} from '@mui/material'
import React from 'react'

const NON_BREAKABLE_SPACE = String.fromCharCode(160)

type Size = 'XL' | 'L' | 'M' | 'S' | 'XS'
type Variant = 'neutral' | 'positive' | 'negative' | 'warning' | 'no-value'

type KpiScoreProps = {
  label: string
  value: number | string | undefined
  type?: 'transparent' | 'boxed'
  variant?: Variant
  size?: Size
  unit?: string
  labelIcon?: React.ReactElement
  valueIcon?: React.ReactElement
  showValueLoader?: boolean
  'data-test-id'?: string
}

const valueStyles: Record<Size, SxProps<Theme>> = {
  XL: {fontSize: 64, lineHeight: '64px', fontWeight: 300},
  L: {fontSize: 36, lineHeight: '36px', fontWeight: 300},
  M: {fontSize: 28, lineHeight: '34px', fontWeight: 300},
  S: {fontSize: 22, lineHeight: '30px', fontWeight: 300},
  XS: {fontSize: 16, lineHeight: '24px', fontWeight: 500}
}

const valueAdormentSizes: Record<Size, SxProps<Theme>> = {
  XL: {width: 40, height: 40},
  L: {width: 32, height: 32},
  M: {width: 24, height: 24},
  S: {width: 16, height: 16},
  XS: {width: 16, height: 16}
}

const getAdormentStyles = (size: Size) => ({
  alignSelf: 'center',
  ...valueAdormentSizes[size],
  [`& .${svgIconClasses.root}`]: {
    ...valueAdormentSizes[size],
    fontSize: 'inherit',
    lineHeight: 'inherit'
  }
})

const labelStyles: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  gap: 0.5,
  [`& .${svgIconClasses.root}`]: {
    width: 16,
    height: 16
  }
}

const unitStyles: SxProps<Theme> = {
  fontSize: 12,
  lineHeight: '16px'
}

const variantColorMap: Record<Variant, TypographyProps['color']> = {
  neutral: 'text.primary',
  positive: 'success.main',
  negative: 'error.main',
  warning: 'warning.main',
  'no-value': 'text.secondary'
}

export const KpiScore: React.FC<KpiScoreProps> = ({
  label,
  value,
  unit,
  type = 'transparent',
  variant = 'neutral',
  size = 'M',
  labelIcon,
  valueIcon,
  'data-test-id': dataTestId,
  showValueLoader = false
}) => {
  const valueElement = !showValueLoader ? (
    <Box sx={{display: 'flex', alignItems: 'center', gap: 0.5}}>
      {valueIcon && <Box sx={getAdormentStyles(size)}>{valueIcon}</Box>}
      <Box sx={{display: 'flex', alignItems: 'baseline', gap: 0.5}}>
        <Typography sx={valueStyles[size]} color={variantColorMap[variant]}>
          {value ?? NON_BREAKABLE_SPACE}
        </Typography>
        {unit && <Typography sx={unitStyles}>{unit}</Typography>}
      </Box>
    </Box>
  ) : (
    <Skeleton sx={valueStyles[size]} />
  )
  const labelElement = (
    <Typography variant="caption" sx={labelStyles}>
      {labelIcon}
      {label}
    </Typography>
  )

  return type === 'transparent' ? (
    <Stack spacing={0.5} data-test-id={dataTestId}>
      {labelElement}
      {valueElement}
    </Stack>
  ) : (
    <Card sx={{padding: size === 'XS' ? 1 : 2, boxShadow: 2}} data-test-id={dataTestId}>
      <Stack spacing={0.5} alignItems="center">
        {valueElement}
        {labelElement}
      </Stack>
    </Card>
  )
}
